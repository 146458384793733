<template>
	<div class="lp">
		<h1 class="lp__header"><span class="lp__header--main"><img src="~@/assets/img/lp_springlethargy2022/springlethargy_title.png" alt="春の体調不良 春バテに注意を"></span><span class="lp__header--zutool"><img src="~@/assets/img/lp_springlethargy2022/springlethargy_title_zutool.png" alt="presented by 頭痛ーる"></span></h1>

		<!-- イントロ -->
		<div class="lp__intro">
			<p class="lp__intro--text">「春バテ」は春に現れる体の不調で、だるさ・気分の落ち込み・疲労感・イライラ・倦怠感などがあります。<br>この春バテの症状を引き起こす原因は<em>&#9312;気象の変化（寒暖差・気圧・日照時間）が大きい</em><em>&#9313;年度変わりで生活の変化によるストレスが大きい</em><em>&#9314;ホルモンバランスの乱れ</em>で、これらにより自律神経が乱れることで症状が現れやすくなります。</p>
		</div>
		<!-- /イントロ -->

		<!-- 目次 -->
		<section class="lp__link">
			<h2 class="lp__link--title">今すぐできる「春バテ」対策</h2>
			<ul class="lp__link--lists">
				<li @click="onScroll('js_prevention')">春バテを予防しよう</li>
				<li @click="onScroll('js_temperature-difference')">春バテ対処法&#9312;気温差</li>
				<li @click="onScroll('js_autonomic-nerves')">春バテ対処法&#9313;自律神経</li>
				<li @click="onScroll('js_hay-fever')">「花粉症皮膚炎」に注意</li>
			</ul>
		</section>
		<!-- /目次 -->

		<!-- prevention -->
		<section class="lp__contents is-prevention" id="js_prevention">
			<h2 class="lp__contents--title">春バテを予防しよう</h2>
			<p class="lp__contents--intro">春バテ予防には<span class="lp__contents--introzutool">「頭痛ーる」</span>がオススメ！頭痛ーるは不調の原因となる「気圧の変化」を事前にお知らせしてくれるアプリです。不調になりやすい日が事前に分かることで、予定の変更や服薬などの予防策がとれます。</p>
			<div class="growthhack-f__caution">
				<p class="growthhack-f__caution--right">低気圧で体調崩すかも。早めに用事を済ませよう！</p>
				<p class="growthhack-f__caution--left">痛みが出る前に薬を準備しておこう！</p>
			</div>
			<div class="lp__contents--inner">
				<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="lp__contents--bnr" id="trackingLpSpringLethargy2022Btn01_android"><img src="@/assets/img/lp_springlethargy2022/springlethargy_push.png" alt="アプリなら低気圧予報をプッシュ通知でお知らせ！ いますぐダウンロード"></a>
				<a v-else @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" class="lp__contents--bnr" id="trackingLpSpringLethargy2022Btn01_iOS"><img src="@/assets/img/lp_springlethargy2022/springlethargy_push.png" alt="アプリなら低気圧予報をプッシュ通知でお知らせ！ いますぐダウンロード"></a>
			</div>
		</section>
		<!-- /prevention -->

		<!-- temperature-difference -->
		<section class="lp__contents is-temperature-difference" id="js_temperature-difference">
			<h2 class="lp__contents--title">春バテ対処法①気温差に対応しよう</h2>
			<p class="lp__contents--intro">気温予報を確認し、外出する場合は気温に合わせて脱いだり着たりしやすい服装を選ぶことで、体への影響を小さくするようにしましょう。屋内であればエアコンなどを利用して室内の温度をできるだけ一定に保つようにしましょう。<br><br>気温差で不調を感じることが多い場合は、「寒暖差疲労」かもしれません。<br>こちらをチェックしてみましょう。</p>
			<div class="lp__contents--inner">
				<h3 class="lp__contents--subtitle">【医師監修】<br> 寒暖差疲労セルフチェック</h3>
				<div class="lp__contents--img"><img src="~@/assets/img/lp_springlethargy2022/temperaturedifference_img.png" alt=""></div>
				<a href="javascript:void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=selfcheckTemperatureDifference` } })" id="trackingLpSpringLethargy2022Btn02" class="lp__contents--link">チェックをはじめる</a>
			</div>
		</section>
		<!-- /temperature-difference -->

		<!-- autonomic-nerves -->
		<section class="lp__contents is-autonomic-nerves" id="js_autonomic-nerves">
			<h2 class="lp__contents--title">春バテ対処法②自律神経を整えよう</h2>
			<p class="lp__contents--intro">交感神経の働きを抑え、副交感神経が優位にするようにし自律神経を整えることでストレスをコントロールすることが春バテの改善につながります。自律神経はストレッチや呼吸法でも整えられます。</p>
			<div class="lp__contents--inner is-stretch">
				<h3 class="lp__contents--subtitle">タオルで簡単！首のばし</h3>
				<div class="lp__contents--img"><img src="~@/assets/img/lp_springlethargy2022/autonomicnerves_01.png" alt=""></div>
				<p class="lp__contents--text">30秒でできる簡単ストレッチ、頭痛や肩こり、ストレートネックの矯正に効果が期待！</p>
				<a href="javascript:void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=columnDetail`, _detailId: 722 } })" id="trackingLpSpringLethargy2022Btn03" class="lp__contents--link">詳しいやり方を見る</a>
			</div>
			<div class="lp__contents--inner is-breath">
				<h3 class="lp__contents--subtitle">自律神経を整える！12秒呼吸法</h3>
				<div class="lp__contents--img"><img src="~@/assets/img/lp_springlethargy2022/autonomicnerves_02.png" alt=""></div>
				<p class="lp__contents--text">寝ながら簡単にできる呼吸法。ゆがんだ骨格をリセットして、自律神経を整えます。</p>
				<a href="javascript:void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=columnDetail`, _detailId: 725 } })" id="trackingLpSpringLethargy2022Btn04" class="lp__contents--link">詳しいやり方を見る</a>
			</div>
			<p class="lp__contents--lead">すでに不調を感じる人は自律神経が乱れているかも！バランスチェックで確認してみましょう。</p>
			<div class="lp__contents--inner is-balance-check">
				<h3 class="lp__contents--subtitle"><span>【医師監修】自律神経の<br>バランスチェック</span></h3>
				<a href="javascript:void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=balancecheckAutonomicNerves` } })" id="trackingLpSpringLethargy2022Btn05" class="lp__contents--link">チェックをはじめる</a>
			</div>
		</section>
		<!-- /autonomic-nerves -->

		<!-- hay-fever -->
		<section class="lp__contents is-hay-fever" id="js_hay-fever">
			<h2 class="lp__contents--title">「花粉症皮膚炎」に注意</h2>
			<p class="lp__contents--intro">春は花粉症もつらい季節。この時期に顔にかゆみが出たり、肌が荒れるなどの症状が出る場合は花粉症皮膚炎の可能性があります。<br>バリア機能が低下した皮膚に花粉が付着すると、炎症を起こし、かゆみや湿疹、乾燥、ヒリヒリした痛みなどの症状が現れ、顔や首、うなじなどの露出した部分に多くみられます。</p>
			<div class="lp__contents--inner">
				<div class="lp__contents--img"><img src="~@/assets/img/lp_springlethargy2022/springlethargy_hayfever.jpg" alt=""></div>
				<h3 class="lp__contents--subtitle">■花粉症皮膚炎の対処法</h3>
				<div class="lp__contents--box">
					<h4 class="lp__contents--boxtitle">・肌のバリア機能を高める</h4>
					<p class="lp__contents--boxtext">花粉症皮膚炎を防ぐには肌のバリア機能を高めることが重要です。肌の保湿や室内の湿度を下げないようにしましょう。また、目をこすったり、かゆみのある部分を搔きむしると肌のバリア機能が低下して花粉症皮膚炎になりやすくなるため注意が必要です。</p>
				</div>
				<div class="lp__contents--box">
					<h4 class="lp__contents--boxtitle">・花粉が肌に触れないようにする</h4>
					<p class="lp__contents--boxtext">外出時にメガネやマスクをしたり、花粉をブロックする化粧品などを使用して花粉を直接肌に触れないようにすることも大切です。寒い日はマフラーなどで首元を覆うと花粉が直接触れることを防ぐことができます。</p>
				</div>
				<div class="lp__contents--box">
					<h4 class="lp__contents--boxtitle">・花粉を洗い流す</h4>
					<p class="lp__contents--boxtext">帰宅したら、すぐに洗顔をしたりシャワーを浴びて花粉を洗い流すことで花粉の接触時間を短くすることも予防につながります。熱いお湯の入浴は肌を乾燥させ肌のバリア機能の低下につながるため、ぬるめのお湯にするのが良いでしょう。</p>
				</div>
				<div class="lp__contents--supervisor">監修者:飯山 隆茂（気象予報士/健康管理士）</div>
			</div>
		</section>
		<!-- /hay-fever -->

		<!-- アウトロ -->
		<section class="lp__outro">
			<img src="~@/assets/img/lp_springlethargy2022/springlethargy_outro.png" alt="">
			<p>春バテ＆花粉症対策で、<br>春を快適に過ごしましょう！</p>
			<div class="lp__back">
				<a @click="$router.push({ name: 'Cpsite', query: { id: sugotokuContentsId } })" class="about-rightnow__login--button">TOPにもどる</a>
			</div>
		</section>
		<!-- /アウトロ -->

		<!-- 下に吸着するアプリダウンロードボタン -->
		<aside class="sticky-bottom-appdownload">
			<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" id="trackingLpSpringLethargy2022Btn06_android" class="sticky-bottom-appdownload--button">
				<img src="~@/assets/img/sugotoku_app_info_btn03.png" alt="今すぐダウンロード" />
			</a>
			<a v-else href="javascript:void(0);" @click="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" id="trackingLpSpringLethargy2022Btn06_iOS" class="sticky-bottom-appdownload--button">
				<img src="~@/assets/img/sugotoku_app_info_btn03.png" alt="今すぐダウンロード" />
			</a>
		</aside>

	</div>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'

// ライブラリ
import cmnConst from '@/assets/js/constant.js'

export default {
	name: 'LpSpringLethargy2022',
	components: {
	},
	data () {
		return {
			backendUrl: cmnConst.BACKEND_URL,
			sugotokuContentsId: cmnConst.SUGOTOKU_CONTENTS_ID
		}
	},
	computed: {
		// map Vuex getters
		...mapGetters('common', ['isAndroid'])
	},
	mounted () {
		// コンテンツの高さをemitする処理（実処理は親で行う）
		// CHANGED: コンテンツの高さを確保する処理の見直しのためコメントアウト
		// this.$nextTick(() => {
		// 	this.$emit('contentHeight')
		// })
	},
	beforeDestroy () {
	},
	methods: {
		// ページスクロール用の関数
		onScroll (id) {
			const elem = document.getElementById(id)
			const options = {
				container: 'body',
				duration: 500,
				easing: 'ease-out',
				offset: 0,
				cancelable: false,
				onStart: false,
				onDone: false,
				onCancel: false,
				x: false,
				y: true
			}
			this.$scrollTo(elem, options)
		}
	}
}
</script>

<style lang="scss" scoped>
@import "~@/assets/sass/variable";

// NOTE: LPページ限定の変数（デザインシステム外のため他での利用禁止）
$spacing-2: 0.125rem;
$spacing-24: 1.5rem;
$spacing-34: 2.125rem;
$spacing-54: 3.375rem;

$line-height-2: 2;

$lp-primary-color: #E96F95;
$lp-secondary-color: #F82063;
$lp-temperaturedifference-border: #FB6010;
$lp-temperaturedifference-backgroud: #FFF6E2;
$lp-temperaturedifference-text: #FF5400;
$lp-temperaturedifference-btn: #F48B56;
$lp-autonomicnerves-color: #48B8DB;

.lp {
	font-family: $lp-family-primary;
	background-color: $background-primary;
	line-height: $line-height;
	color: $text-primary;
	font-size: $font-size-16;
	max-width: 640px;
	margin: 0 auto;

	&__header {
		margin: 0 auto;
		padding: $spacing-10 $spacing-14 0;

		&--main {
			img {
				display: block;
				width: 100%;
			}
		}

		&--zutool {
			margin-top: $spacing-2;
			display: flex;
			justify-content: flex-end;

			img {
				display: block;
				width: 44.67%;
			}
		}
	}

	&__intro {
		margin: $spacing-10 0 0 0;
		padding: 0 $spacing-14;

		em {
			display: block;
			color: $lp-primary-color;
			font-family: $lp-family-secondary;
			font-style: normal;
			font-weight: normal;
		}

		&--text { margin: 0; }
	}

	&__link {
		margin-top: $spacing-30;
		padding: 0 $spacing-14;
		width: 100%;
		box-sizing: border-box;

		&--title {
			margin: 0;
			padding: $spacing-10;
			border-radius: 100px;
			color: $_white;
			background-color: $lp-primary-color;
			font-family: $lp-family-tertiary;
			font-weight: normal;
			font-size: $font-size-20;
			text-align: center;

			@media (max-width: (340px)) { font-size: $font-size-18; }
		}

		&--lists {
			margin: $spacing-14 0 0;
			padding: 0;

			li {
				padding-left: $spacing-40;
				color: $lp-secondary-color;
				font-family: $lp-family-secondary;
				font-size: $font-size-18;
				text-decoration: underline;
				line-height: $line-height-2;
				background: url(~@/assets/img/lp_springlethargy2022/springlethargy_linklist.png) left 4px / 30px 30px no-repeat;
			}
		}
	}

	&__contents {
		&--title {
			margin: $spacing-30 $spacing-14 0;
			padding: $spacing-54 0 0;
			position: relative;
			font-size: $font-size-20;
			font-family: $lp-family-secondary;
			font-weight: normal;
			color: $lp-secondary-color;
			text-align: center;
			line-height: $line-height-1;
			white-space: nowrap;
			background: url(~@/assets/img/lp_springlethargy2022/springlethargy_titlebg.png) center top / 100% auto no-repeat;

			@media (max-width: (340px)) { font-size: $font-size-18; }
			@media (min-width: (400px)) { background-size: 400px; }
		}

		&--intro {
			margin: $spacing-26 0 0 0;
			padding: 0 $spacing-14;
		}

		&--introzutool {
			color: $primary;
		}

		&.is-prevention {
			.lp__contents--inner {
				margin: $spacing-20 0 0 0;
				padding: 0 $spacing-14;
			}
			.lp__contents--bnr {
				display: block;

				img {
					display: block;
					width: 100%;
				}
			}

			.growthhack-f__caution { margin-top: $spacing-20; font-size: $font-size-14; }
		}

		&.is-temperature-difference {
			.lp__contents--inner {
				margin: $spacing-20 $spacing-20 0;
				padding: $spacing-26 0;
				border: 2px solid $lp-temperaturedifference-border;
				border-radius: 10px;
				background-color: $lp-temperaturedifference-backgroud;
			}

			.lp__contents--subtitle {
				margin: 0;
				color: $lp-temperaturedifference-text;
				text-align: center;
				font-size: $font-size-24;
				font-family: $lp-family-tertiary;
				font-weight: normal;

				@media (max-width: (340px)) { font-size: $font-size-18; }
			}

			.lp__contents--img {
				img {
					margin: 0 auto;
					display: block;
					width: 61.225%;
					max-width: 100%;
				}
			}

			.lp__contents--link {
				margin: 0 auto;
				padding: $spacing-14 0;
				border-radius: 6px;
				display: block;
				width: 89.125%;
				color: $text-seconday;
				background-color: $lp-temperaturedifference-btn;
				text-align: center;
				font-size:  $font-size-18;
				text-decoration: none;
				line-height: $line-height-1;
			}
		}

		&.is-autonomic-nerves {
			.lp__contents--inner {
				margin: $spacing-18 $spacing-14 0;
				padding: $spacing-24 0 $spacing-16;
				border: 2px solid $lp-autonomicnerves-color;
				border-radius: 10px;
			}

			.lp__contents--subtitle {
				margin: 0 $spacing-20;
				padding-bottom: $spacing-14;
				position: relative;
				text-align: center;
				font-family: $lp-family-secondary;
				font-weight: normal;
				font-size: $font-size-20;
				line-height: $line-height-1;

				&::after {
					content: "";
					display: block;
					position: absolute;
					bottom: 0;
					width: 100%;
					height: 1px;
					background: linear-gradient(to right, $lp-autonomicnerves-color, $lp-autonomicnerves-color 3px, transparent 3px, transparent 6px) left top / 6px 1px repeat-x;
				}

				@media (max-width: (340px)) { font-size: $font-size-16; }
			}

			.lp__contents--text {
				margin: 0;
				padding: $spacing-10 $spacing-18 0;
			}

			.lp__contents--lead {
				margin: 0;
				padding: $spacing-20 $spacing-14 0;
			}

			.lp__contents--link {
				margin: $spacing-10 auto 0;
				padding: $spacing-14 0;
				border-radius: 6px;
				display: block;
				width: 86.01%;
				background-color: $lp-autonomicnerves-color;
				text-align: center;
				color: $text-seconday;
				font-family: $lp-family-secondary;
				font-size:  $font-size-18;
				text-decoration: none;
				line-height: $line-height-1;
			}

			.is-stretch {
				img {
					margin: $spacing-8 auto 0;
					display: block;
					width: 90.09%;
				}
			}

			.is-breath {
				img {
					margin: $spacing-18 auto 0;
					display: block;
					width: 91.548%;
				}
			}

			.is-balance-check {
				margin-top: $spacing-8;
				padding: $spacing-18 0;

				.lp__contents--subtitle {
					padding-bottom: $spacing-20;

					span {
						position: relative;
						display: inline-block;
						line-height: $line-height;

						&::before {
							content: "";
							position: absolute;
							left: -50px;
							top: 4px;
							width: 52px;
							height: 62px;
							background: url(~@/assets/img/lp_springlethargy2022/autonomicnerves_moon.png) left top / 100% auto no-repeat;

							@media (max-width: (340px)) { left: -40px; width: 42px; height: 50px; }
						}

						&::after {
							content: "";
							position: absolute;
							right: -60px;
							top: 3px;
							width: 69px;
							height: 67px;
							background: url(~@/assets/img/lp_springlethargy2022/autonomicnerves_sun.png) left top / 100% auto no-repeat;

							@media (max-width: (340px)) { right: -54px; width: 59px; height: 57px; }
						}
					}
				}

				.lp__contents--link {
					margin-top: 20px;
				}
			}
		}

		&.is-hay-fever {
			.lp__contents--inner {
				margin: $spacing-26 0 0;
			}

			.lp__contents--img {
				img {
					margin: 0 auto;
					display: block;
					width: 34.67%;
				}
			}

			.lp__contents--subtitle {
				margin: $spacing-14 0 0;
				padding: 0 $spacing-14;
				color: $lp-primary-color;
				font-size: $font-size-16;
			}

			.lp__contents--box + .lp__contents--box {
				margin-top: $spacing-24;
			}

			.lp__contents--boxtitle {
				margin: 0;
				padding: 0 $spacing-14;
				font-size: $font-size-16;
			}

			.lp__contents--boxtext {
				margin: 0;
				padding: 0 $spacing-14;
			}

			.lp__contents--supervisor {
				margin: $spacing-26 0 0;
				padding: 0 $spacing-14;
				font-size: $font-size-14;
			}
		}
	}

	&__outro {
		margin: $spacing-30 0 0 0;

		img {
			margin: 0 auto;
			display: block;
			width: 53.334%;
		}

		p {
			margin: 0;
			text-align: center;
		}
	}

	&__back {
		margin: $spacing-34 0 0 0;

		.about-rightnow__login--button {
			border-radius: 100px;
			color: $text-seconday;
			font-weight: normal;
			font-size: $font-size-24;
			background-color: $primary;
		}
	}
}

.sticky-bottom-appdownload {
	margin-top: $spacing-20;
	padding: 0;
}
</style>
